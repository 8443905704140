<template>
  <div>
    <template v-if="hasFiles">
      <v-row>
        <v-col>
          <div class="d-flex" @click="handleManegeStore()">
            <v-progress-circular
              :rotate="-90"
              :size="45"
              :value="value"
              color="primary"
            >
              <small>{{ value }}% </small>
            </v-progress-circular>
            <div class="ml-2">
              <small>Armazenamento</small>
              <small>
                <h5 class="mt-0">{{ totalSpaceUsed }} / {{ totalSpace }}</h5>
              </small>
            </div>
          </div>
        </v-col>
        <v-col>
          <v-btn
            class="d-flex ml-auto"
            color="primary"
            @click="handleStoreDocument()"
          >
            Anexar Arquivos
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <app-pagination @click="select($event)" :data="files">
            <v-list dense>
              <v-list-item-group>
                <v-divider />

                <template v-for="(file, index) in files.data">
                  <v-list-item class="pr-0" :key="index">
                    <v-list-item-content>
                      <v-list-item-title>{{ file.name }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ $format.bytesToSize(file.size) }}
                        -
                        {{ $format.dateBr(file.created_at) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <div class="d-flex">
                        <v-btn
                          @click.stop="handleDownloadFile(file)"
                          text
                          icon
                          color="primary"
                        >
                          <v-icon>mdi-download</v-icon>
                        </v-btn>
                        <app-delete-btn
                          @click="handleDeleteFile(file.id)"
                          type="icon"
                        />
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider :key="'divider' + file.id" />
                </template>
              </v-list-item-group>
            </v-list>
          </app-pagination>
        </v-col>
      </v-row>
    </template>

    <template v-if="!hasFiles">
      <v-row>
        <v-col class="d-flex flex-column align-center mt-10" cols="12" md="12">
          <div>
            <v-img width="130px" src="@/assets/images/files.png" />
          </div>
          <div class="text-center">
            <h2 class="mt-5">
              Guarde aqui os arquivos do seu {{ customerAlias() }}!
            </h2>
            <br />
            e tenha acesso rápido a eles quando precisar.
          </div>
          <div class="text-center">
            <v-btn
              class="mt-5 mx-2"
              bottom
              large
              @click="handleStoreDocument()"
              color="primary"
            >
              Anexar Arquivos
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </template>
    <FileUploadForm
      ref="FileUploadForm"
      :params="fileParams"
      :documentData="customer"
      :url="url"
      @store="select()"
    />
  </div>
</template>

<script>
import FileUploadForm from "@/components/app/forms/FileUploadForm";
import { customerAlias } from "@/utils/customerAlias";
export default {
  components: { FileUploadForm },

  props: {
    customer: {},
  },

  data() {
    return {
      url: "/customer/customer-files",

      customerAlias,
      searchParams: {},
      files: {
        data: [],
      },
      headers: [
        { text: "Descrição", align: "start", value: "name" },

        { text: "", value: "action", align: "center", width: "1%" },
      ],
      loading: false,
    };
  },

  computed: {
    fileParams() {
      return {
        customer_id: this.customer.id,
        collection: "customer",
      };
    },
    hasFiles() {
      return this.files.data.length > 0;
    },

    company() {
      return this.$store.state.auth.company;
    },
    totalSpace() {
      return this.$format.bytesToSize(this.company.storage_space);
    },
    totalSpaceUsed() {
      return this.$format.bytesToSize(this.company.storage_space_used);
    },

    value() {
      return Math.round(
        (this.company.storage_space_used * 100) / this.company.storage_space
      );
    },
  },
  created() {
    this.searchParams.customer_id = this.$route.params.id;
  },
  methods: {
    handleStoreDocument() {
      this.$refs.FileUploadForm.open(this.customer.id);
    },

    handleDeleteFile(file_id) {
      this.$loading.start();
      this.$http
        .destroy(this.url, file_id)
        .then((response) => {
          this.select();
          this.$loading.finish();
        })
        .catch((error) => {
          this.select();
          this.$loading.finish();
        });
    },

    async select(page) {
      this.searchParams.page = page;
      this.loading = true;
      await this.$http
        .index(this.url, this.searchParams)
        .then((response) => {
          this.files = response.files;
          this.loading = false;
          this.$auth.getLoginData();
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    handleDownloadFile(file) {
      this.$loading.start();
      this.$http
        .download(`${this.url}/download`, { id: file.id })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", file.name);
          document.body.appendChild(link);
          link.click();
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    handleManegeStore() {
      this.$router.push("/configuracoes/armazenamento");
    },
  },
};
</script>

<style>
</style>