<template>
  <div id="main-container" class="d-flex flex-column">
    <div id="customer-name-block" class="d-flex mb-5">
      <div class="mr-6">
        <CustomerAvatar :name="customer.name" :size="'50px'" />
      </div>
      <div class="d-flex flex-column">
        <div>
          <h3>{{ customer.name }}</h3>
        </div>
        <div class="justify-center">
          <app-icon small class="pr-2">mdi-whatsapp</app-icon>
          <small v-if="customer.phone_wa">{{ customer.phone_wa }}</small>
          <small v-else> - </small>
        </div>
        <div class="justify-center">
          <app-icon small class="pr-2">mdi-email</app-icon>
          <small v-if="customer.email">{{ customer.email }}</small>
          <small v-else> - </small>
        </div>
        <div class="justify-center">
          <app-icon small class="pr-2">mdi-account-plus</app-icon>
          <small>{{ $format.dateBr(customer.created_at) }}</small>
        </div>
        <div class="justify-center" v-if="hasBirthDate">
          <app-icon small class="pr-2">mdi-cake-variant</app-icon>
          <small>{{ age }}</small>
        </div>
      </div>
    </div>
    <v-card outlined>
      <v-card-text class="pa-6">
        <div class="d-flex justify-space-between" style="width:100%">
          <div>
            <div class="text-center">
              <v-avatar size="40px" color="#EDEAFC">
                <app-icon color="primary">event</app-icon>
              </v-avatar>
            </div>
            <div class="text-center mt-2">
              <span class="secondary--text">Sessões</span>
            </div>
            <div class="text-center">
              <h3 class="secondary--text">{{ stats.sessions }}</h3>
            </div>
          </div>
          <div>
            <div class="text-center">
              <v-avatar size="40px" color="#E6F2E6">
                <app-icon color="success">done</app-icon>
              </v-avatar>
            </div>
            <div class="text-center mt-2">
              <span class="secondary--text">Atendidas</span>
            </div>
            <div class="text-center">
              <h3 class="secondary--text">{{ stats.shown }}</h3>
            </div>
          </div>
          <div>
            <div class="text-center">
              <v-avatar size="40px" color="#FBE7E7">
                <app-icon color="error">error</app-icon>
              </v-avatar>
            </div>
            <div class="text-center mt-2">
              <span class="secondary--text">Faltas</span>
            </div>
            <div class="text-center">
              <h3 class="secondary--text">{{ stats.missed }}</h3>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-alert v-if="showDueTransactionsAlert" class="mt-4 mb-0" color="primary" text >
      <div class="d-flex justify-space-between" style="width:100%">
        <div>
          Total vencidos: 
        </div>
        <div>
          {{ $format.decimal(dueTransactionsSum) }}
        </div>
      </div>
    </v-alert>
  </div>
</template>

<script>
import CustomerAvatar from "./CustomerAvatar.vue";
import { parseISO } from "date-fns";

export default {
  components: {
    CustomerAvatar,
  },

  props: {
    customer: {
      type: Object,
      required: true,
    },
    stats: {
      type: Object,
      required: true,
    },
    
    dueTransactionsSum : {
      required: true,  
    }
  },

  computed: {
    showDueTransactionsAlert() {
      return this.dueTransactionsSum > 0;
    },
    hasBirthDate() {
      return this.customer.birth_date != null;
    },
    age() {
      if (!this.hasBirthDate) return null;

      const birthDate = parseISO(this.customer.birth_date);
      const now = new Date();

      const ageInYears = now.getFullYear() - birthDate.getFullYear();
      const ageInMonths = now.getMonth() - birthDate.getMonth();

      if (
        ageInMonths < 0 ||
        (ageInMonths === 0 && now.getDate() < birthDate.getDate())
      ) {
        return `${ageInYears - 1} anos e ${12 + ageInMonths} meses`;
      } else {
        return `${ageInYears} anos e ${ageInMonths} meses`;
      }
    },
  },
};
</script>

<style></style>
