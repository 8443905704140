var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"text-end py-10"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.handleAddMonth()}}},[_vm._v("Adicionar")])],1)],1),_c('app-pagination',{attrs:{"data":_vm.monthly_billings},on:{"click":function($event){return _vm.select($event)}}},[(_vm.$vuetify.breakpoint.mobile)?[_c('div',{attrs:{"id":"monthly_billings-list-mobile"}},[_vm._l((_vm.monthly_billings.data),function(item,index){return [_c('div',{class:_vm.getHoverClass(index),on:{"click":function($event){return _vm.handleOpenMonthlyCharge(item.id)}}},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"descriptions pr-4"},[_c('div',{staticClass:"body-04"},[_vm._v(" De "+_vm._s(_vm.$format.dateBr(item.start_date))+" Até "+_vm._s(_vm.$format.dateBr(item.end_date))+" ")]),_c('div',{staticClass:"body-03"},[_vm._v(" Dia de cobrança: "+_vm._s(item.day_of_month)+" ")])]),_c('div',{staticClass:"ml-auto totals"},[_vm._v(" R$ "+_vm._s(_vm.$format.decimal(item.value))+" ")])])]),_c('div',{staticClass:"mx-6"},[(index < _vm.monthly_billings.data.length - 1)?_c('v-divider',{key:("divider" + index)}):_vm._e()],1)]})],2)]:_vm._e(),(!_vm.$vuetify.breakpoint.mobile)?[_c('div',{attrs:{"id":"monthly_billings-list-desktop"}},[_c('br'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.monthly_billings.data,"items-per-page":-1,"hide-default-footer":"","disable-sort":"","dense":""},on:{"click:row":function($event){return _vm.handleOpenMonthlyCharge($event.id)}},scopedSlots:_vm._u([{key:"item.day_of_month",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.day_of_month)+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" R$ "+_vm._s(_vm.$format.decimal(item.value))+" ")]}},{key:"item.start_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.dateBr(item.start_date))+" ")]}},{key:"item.end_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.dateBr(item.end_date))+" ")]}}],null,true)})],1)]:_vm._e()],2),_c('MonthlyBillingForm',{ref:"MonthlyBillingForm",on:{"store":function($event){return _vm.select()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }