<template>
  <div>
    <portal to="mobileMenuButton">
      <v-btn icon @click.prevent="$router.push('/cliente/' + $route.params.id)">
        <app-icon>mdi-arrow-left</app-icon>
      </v-btn>
    </portal>
    <CustomerProfessionalRecordForm
      class="mb-6"
      @input="update()"
      ref="CustomerProfessionalRecordForm"
      @delete="select()"
      :customer="customer"
    />
  </div>
</template>

<script>

import CustomerProfessionalRecordForm from "../../components/customers/forms/CustomerProfessionalRecordForm.vue";

export default {
  components: { CustomerProfessionalRecordForm },
  data: () => ({
    customer: {},
  }),

  created() {
    this.select();
  },

  methods: {
    async select() {
      this.$loading.start();

      await this.$http
        .show("customer/customer", this.$route.params.id)
        .then((response) => {
          this.customer = response.customer;
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    update() {
      this.$http
        .update("customer/customer", this.customer.id, this.customer)
        .then((response) => {
          console.log(response);
          this.processSaved();
        });
    },
  },
};
</script>

<style></style>
