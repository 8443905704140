<template>
  <div>
    <portal to="mobileMenuButton">
      <v-btn icon @click.prevent="$router.push('/cliente/' + $route.params.id)">
        <app-icon>navigate_before</app-icon>
      </v-btn>
    </portal>
    <CustomerFileSection
      ref="CustomerFileSection"
      :customer="customer"
    />
  </div>
</template>

<script>
import CustomerFileSection from "../../components/customers/sections/CustomerFileSection.vue";
export default {
  components: { CustomerFileSection },
  data: () => ({
    customer: {},
  }),

  created() {
    this.select();
  },

  methods: {
    async select() {
      this.$loading.start();

      await this.$http
        .show("customer/customer", this.$route.params.id)
        .then((response) => {
          this.customer = response.customer;
          this.$refs.CustomerFileSection.select();
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
  },
};
</script>

<style></style>
