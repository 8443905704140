<template>
  <div>
    <BaseCustomerForm v-model="form" />
    <v-row>
      <v-col class="text-right">
        <v-btn @click="update()" color="primary"> Salvar </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BaseCustomerForm from "./BaseCustomerForm.vue";
import { customerAlias } from "@/utils/customerAlias";
export default {
  components: { BaseCustomerForm },

  props: {
    customer: {},
  },

  data() {
    return {
      form: {},
    };
  },

  watch: {
    customer() {
      this.form = JSON.parse(JSON.stringify(this.customer));
    },
  },

  methods: {
    update() {
      this.$loading.start();
      this.$http
        .update("customer/customer", this.form.id, this.form)
        .then((response) => {
          this.processSaved();
        })
        .catch((response) => {
          this.$loading.finish();
        });
    },

    processSaved() {
      this.form.professional_id = this.form.professional
        ? this.form.professional.id
        : null;

      this.$store.commit("onboarding/customerStored");

      this.$emit("store", this.form);

      this.$loading.finish();

      this.$snackbar({
        message: "Cadastro atualizado!",
        color: "success",
        timeout: 1000,
        showClose: false,
      });
    },
  },
};
</script>

<style scoped></style>
