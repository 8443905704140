<template>
  <div>
    <v-row>

      <v-col class="text-end py-10">
        <v-btn @click="handleAddMonth()" color="primary">Adicionar</v-btn>
      </v-col>
    </v-row>


    <app-pagination @click="select($event)" :data="monthly_billings">
      <!-- <Monthly_billingsList v-if="$vuetify.breakpoint.mobile" :monthly_billings="monthly_billings"
        @click="handleOpenMonthlyCharge($event.id)" /> -->

      <template v-if="$vuetify.breakpoint.mobile">

        <div id="monthly_billings-list-mobile">


          <template v-for="(item, index) in monthly_billings.data">
            <div @click="handleOpenMonthlyCharge(item.id)" :class="getHoverClass(index)">
              <div class="item">

                <div class="descriptions pr-4">
                  <div class="body-04">
                    De {{ $format.dateBr(item.start_date) }} Até {{ $format.dateBr(item.end_date) }}
                  </div>
                  <div class="body-03">
                    Dia de cobrança: {{ item.day_of_month }}
                  </div>
                </div>

                <div class="ml-auto totals">
                  R$ {{ $format.decimal(item.value) }}
                </div>

              </div>
            </div>
            <div class="mx-6">
              <v-divider :key="`divider${index}`" v-if="index < monthly_billings.data.length - 1" />
            </div>
          </template>
        </div>
      </template>



      <template v-if="!$vuetify.breakpoint.mobile">
        <div id="monthly_billings-list-desktop">
          <br />
          <v-data-table :headers="headers" :items="monthly_billings.data" :items-per-page="-1" hide-default-footer
            @click:row="handleOpenMonthlyCharge($event.id)" disable-sort dense>

            <template v-slot:[`item.day_of_month`]="{ item }">
              {{ item.day_of_month }}
            </template>
            <template v-slot:[`item.value`]="{ item }">
              R$ {{ $format.decimal(item.value) }}
            </template>

            <template v-slot:[`item.start_date`]="{ item }">
              {{ $format.dateBr(item.start_date) }}
            </template>

            <template v-slot:[`item.end_date`]="{ item }">
              {{ $format.dateBr(item.end_date) }}
            </template>


          </v-data-table>
        </div>
      </template>
    </app-pagination>
    <MonthlyBillingForm ref="MonthlyBillingForm" @store="select()" />
  </div>
</template>

<script>

import MonthlyBillingForm from "@/components/transactions/form/MonthlyBillingForm.vue";

export default {

  components: {
    MonthlyBillingForm
  },

  props: {
    customer: {},
  },

  data() {
    return {
      headers: [
        { text: "Dia de cobrança", value: "day_of_month", width: "12%" },
        { text: "Valor", value: "value", align: "right" },
        { text: "Início", value: "start_date", align: "right" },
        { text: "Fim", value: "end_date", align: "right" },
      ],
      monthly_billings: {},
    };
  },


  mounted() {
    this.select();
  },

  methods: {
    getHoverClass(index) {
      if (index == 0) {
        return "first-hover";
      }
      if (index == this.monthly_billings.data.length - 1) {
        return "end-hover";
      }
      return "item-hover";
    },

    select() {
      this.$loading.start();

      this.$http.index('transactions/monthly-billing', { customer_id: this.customer.id }).then((response) => {
        this.monthly_billings = response.monthly_billings;
        this.$loading.finish()
      })
        .catch((error) => this.$loading.finish());
    },

    handleAddMonth() {
      this.$refs.MonthlyBillingForm.open(this.customer);
    },

    handleOpenMonthlyCharge(id) {
      this.$refs.MonthlyBillingForm.open(this.customer, id);
    },
  },
};
</script>


<style lang="scss">
#monthly_billings-list-desktop {
  .body-02 {
    color: #624DE3;
    font-size: 16px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .body-03 {
    color: #8C8A97;
    font-size: 14px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
  }

  .body-04 {
    color: #AAA8B8;
    font-size: 12px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 1px;
  }

  .item {
    display: flex;
    flex-grow: 0.6;
    align-items: center;
    padding: 8px;
    cursor: pointer;
  }
}

#monthly_billings-list-mobile {
  .descriptions {
    text-align: left;
    width: 600px;
    text-justify: justify-all;
  }

  .totals {
    text-align: center;
    width: 100%;
  }

  h3 {
    color: #33303E;
    font-size: 18px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    line-height: 27px;
  }

  .body-02 {
    color: #907FF5;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
  }

  .body-03 {
    color: #8C8A97;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 1px;
  }

  .body-04 {
    color: #8C8A97;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 1px;
  }

  .item {
    display: flex;
    flex-grow: 1;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 0px;
    padding-right: 0px;
  }
}
</style>