<template>
  <div>
    <template v-if="!customerAnamnese.anamnese">
      <v-row class="mt-8">
        <v-col class="text-center" cols="12">
          <v-btn color="primary" @click="handleSelectAnamnese()"
            >Criar nova anamnese</v-btn
          >
          <AnamneseSearch
            @input="handleAnamneseSelected($event)"
            ref="AnamneseSearch"
          />
        </v-col>
      </v-row>
    </template>
    <template v-if="customerAnamnese.anamnese">
      <div class="d-flex justify-end">
        <v-btn @click="handleSave()" class="mr-2" outlined color="primary">
          Salvar
        </v-btn>
        <v-btn @click="handlePrint()" icon color="primary">
          <app-icon>print</app-icon>
        </v-btn>
        <app-delete-btn
          v-if="hasAnamnese"
          @click="handleDelete()"
          type="icon"
        />
      </div>
      <v-row class="mt-10">
        <v-col class="pa-0 mx-4">
          <draggable
            v-model="anamnese"
            @start="drag = true"
            handle=".handle"
            @end="drag = false"
          >
            <template v-for="(question, index) in anamnese">
              <div :key="index">
                <template v-if="question.type == 'question'">
                  <v-card outlined class="mb-6">
                    <v-card-text class="col">
                      <div>
                        <span class="d-flex" style="font-size: 18px"
                          >{{ question.question }}
                          <v-btn
                            class="ml-auto"
                            text
                            icon
                            @click="openAnamneseQuestionForm(question, index)"
                            ><v-icon>mdi-pencil</v-icon></v-btn
                          >
                          <app-delete-btn
                            @click="removeQuestion(index)"
                            type="icon"
                          />
                          <v-btn icon text>
                            <app-icon class="handle"> drag_indicator </app-icon>
                          </v-btn>
                        </span>
                        <v-radio-group
                          class="mt-0"
                          v-if="question.question_type == 'single_option'"
                          column
                          v-model="question.answer"
                        >
                          <v-radio
                            v-for="(option, index) in question.options"
                            :label="option"
                            color="primary"
                            :value="option"
                            :key="index"
                          ></v-radio>
                        </v-radio-group>
                        <div
                          v-if="question.question_type == 'multiple_options'"
                          column
                        >
                          <v-checkbox
                            class="mt-0"
                            v-for="(option, index) in question.options"
                            v-model="question.answer"
                            :label="option"
                            color="primary"
                            :value="option"
                            :key="index"
                          ></v-checkbox>
                        </div>

                        <div v-if="question.question_type == 'open'">
                          <v-textarea
                            v-model="question.answer"
                            solo
                            flat
                            background-color="grey lighten-4"
                            auto-grow
                            placeholder="Resposta..."
                            full-width
                          />
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </template>
                <template v-if="question.type == 'heading'">
                  <div
                    class="d-flex justify-space-between pr-4"
                    style="widht: 100%"
                  >
                    <h3 class="mt-6 mb-2">
                      {{ question.heading }}
                    </h3>
                  </div>
                </template>
              </div>
            </template>
          </draggable>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-0 mx-4">
          <v-card @click="openAnamneseQuestionForm()" outlined>
            <v-card-text
              style="font-size: 125%"
              class="d-flex justify-center"
              primary-title
            >
              Adicionar Pergunta
              <v-icon class="ml-2">mdi-plus</v-icon>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-end mt-12 mx-2">
        <app-delete-btn
          v-if="customerAnamnese.id"
          class="mr-auto"
          @click="handleDelete()"
        />
        <v-btn @click="handleSave()" color="primary">Salvar</v-btn>
      </v-row>
    </template>
    <AnamneseQuestionForm
      ref="AnamneseQuestionForm"
      @update="updateQuestion($event)"
      @store="addQuestion($event)"
    />
  </div>
</template>

<script>
import AnamneseSearch from "@/components/anamnese/ui/AnamneseSearch.vue";
import AnamneseQuestionForm from "@/components/anamnese/forms/AnamneseQuestionForm";
import draggable from "vuedraggable";

export default {
  components: { AnamneseSearch, AnamneseQuestionForm, draggable },
  props: {
    value: {},
    customer: {},
  },
  data() {
    return {
      anamnese: [],
      customerAnamnese: { anamnese: [] },
      baseCustomerAnamnese: {
        customer_id: null,
        name: null,
        anamnese: null,
      },
    };
  },

  watch: {
    customer(val) {
      this.setAnamneseData();
    },

    customerAnamnese: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.mapAnameses();
      },
    },
  },

  created() {
    this.reset();
  },

  computed: {
    hasAnamnese() {
      return this.customerAnamnese.id;
    },
  },

  methods: {
    reset() {
      this.customerAnamnese = JSON.parse(
        JSON.stringify(this.baseCustomerAnamnese)
      );
    },
    updateQuestion(data) {
      this.$set(this.anamnese, this.editIndex, data);
    },
    async addQuestion(data) {
      await this.anamnese.push({
        type: "question",
        question: data.question,
        question_type: data.question_type,
        answer: data.answer,
        options: data.options,
      });
    },

    removeQuestion(index) {
      this.$delete(this.anamnese, index);
    },
    openAnamneseQuestionForm(question, index) {
      this.editIndex = index;
      this.$refs.AnamneseQuestionForm.open(question, index);
    },

    setAnamneseData() {
      if (this.customer.customer_anamnese) {
        this.customer.customer_anamnese.anamnese = JSON.parse(
          this.customer.customer_anamnese.anamnese
        );
        this.customerAnamnese = this.customer.customer_anamnese;
      } else {
        this.customerAnamnese.anamnese = null;
      }

      this.customerAnamnese.customer_id = this.customer.id;
    },

    handleSelectAnamnese() {
      this.$refs.AnamneseSearch.open();
    },

    handleAnamneseSelected(anamnese) {
      anamnese.questions = JSON.parse(anamnese.questions);

      this.customerAnamnese.anamnese_id = anamnese.id;
      this.customerAnamnese.name = anamnese.name;
      this.customerAnamnese.anamnese = anamnese.questions;
    },

    handleSave() {
      this.customerAnamnese.anamnese = this.anamnese;
      this.customerAnamnese.customer_id = this.customer.id;
      this.$http
        .store("customer/customer-anamnese", this.customerAnamnese)
        .then((response) => {
          this.processSaved();
        })
        .catch((error) => {});
    },
    handleDelete() {
      this.$loading.start();
      this.$http
        .destroy("customer/customer-anamnese", this.customerAnamnese.id)
        .then((response) => {
          this.$snackbar({
            message: "Anamnese apagada com sucesso",
            color: "success",
            timeout: 1000,
            showClose: false,
          });
          this.$emit("delete");
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    processSaved() {
      this.$emit("store");
      this.$snackbar({
        message: "Anamnese salva com sucesso",
        color: "success",
        timeout: 1000,
        showClose: false,
      });
    },
    handlePrint() {
      this.$doc.printView(`/cliente/${this.customer.id}/imprimir/anamnese`);
    },

    mapAnameses() {
      if (this.customerAnamnese.anamnese) {
        this.anamnese = this.customerAnamnese.anamnese.map((anamnese) => {
          if (anamnese.question_type == "open") {
            return {
              answer: anamnese.answer ?? null,
              question: anamnese.question,
              question_type: anamnese.question_type,
              type: anamnese.type,
            };
          }

          if (anamnese.question_type == "multiple_options") {
            return {
              answer: anamnese.answer ?? [],
              question: anamnese.question,
              question_type: anamnese.question_type,
              type: anamnese.type,
              options: anamnese.options,
            };
          }

          if (anamnese.question_type == "single_option") {
            return {
              answer: anamnese.answer ?? null,
              question: anamnese.question,
              question_type: anamnese.question_type,
              type: anamnese.type,
              options: anamnese.options,
            };
          }
          if (anamnese.type == "heading") {
            return {
              heading: anamnese.heading,
              type: anamnese.type,
            };
          }
        });
      }
    },
  },
};
</script>

<style>
</style>