<template>
  <v-container class="pa-0" fluid style="height: 100%">
    <div class="d-flex flex-md-row flex-column" style="height: 100%">
      <div class="column order-1 order-md-0 left-column d-flex align-md-center align-start pa-4"
        style="background-color: #624de3">
        <v-img class="left-column-image" contain src="../../assets/images/auth/woman_hug.png" />
      </div>

      <div class="column order-0 order-md-1 d-flex flex-column justify-center pl-md-12 px-4 py-8"
        style="background-color: #fff">
        <div id="logo" class="mb-md-15 mb-6">
          <v-img width="144" src="../../assets/images/auth/logo_roxo.png"></v-img>
        </div>

        <div id="heading" class="mb-md-15 mb-6">
          <h1 class="mb-2">Bem vindo!</h1>
          <p>Entre com seu e-mail e senha para acessar sua conta.</p>
        </div>

        <div id="login-form" class="form">
          <div class="mb-4" style="width: 100%">
            <v-text-field solo flat background-color="#F4F4FB" placeholder="Digite seu E-MAIL" hide-details="auto"
              v-model="form.email" type="email" />
          </div>
          <div class="mb-4" style="width: 100%">
            <app-password-field solo :dense="false" flat background-color="#F4F4FB" placeholder="Sua SENHA"
              hide-details="auto" @keyup.enter.native="login" v-model="form.password" type="password" />
          </div>
          <div class="d-md-flex justify-space-between mb-4">
            <div class="mb-4 mb-md-0">
              <small class="text--secondary">Esqueceu sua </small>
              <small class="primary--text" @click="forgotPassword()" style="cursor: pointer"> Senha? </small>
            </div>
            <div>
              <small class="text--secondary">Não possui conta?</small>
              <small class="primary--text" @click="redirectToSignup()" style="cursor: pointer"> Experimente Grátis
              </small>
            </div>
          </div>
          <div>
            <v-btn @click="login()" class="primary" dark rounded>
              FAÇA LOGIN
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      form: {
        email: null,
        password: null,
      },
    };
  },

  methods: {
    login() {
      this.$loading.start();
      this.params = {
        email: this.form.email?.replace(/\s/g, ""),
        password: this.form.password,
      };

      this.$auth
        .login(this.params)
        .then(() => {
          this.$loading.finish();
          this.track();
          this.$router.push("/");
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    track() {
      this.$tracker.login();
    },

    forgotPassword() {
      this.$router.push("recuperar-senha");
    },

    redirectToSignup() {
      let query = this.$route.query;

      this.$router.push({ path: "/criar-conta", query });
    },
  },
};
</script>

<style scoped>
.left-column {
  background-color: #624de3;
  background-image: url("../../assets/images/auth/bg.png");
  background-size: cover;
}

/* desktop */
@media (min-width: 960px) {
  .column {
    width: 50%;
    height: 100%;
  }

  .form {
    padding-right: 156px;
  }

  .left-column-image {
    height: 600px;
  }
}

/* mobile*/
@media (max-width: 960px) {
  .column {
    width: 100%;
  }

  .left-column-image {
    height: 400px;
  }
}
</style>

 