<template>
  <div>
    <template v-if="!customerProfessionalRecord.professional_record">
      <v-row class="mt-8">
        <v-col class="text-center" cols="12">
          <v-btn @click="handleSelectRecord()" color="primary">
            Novo Prontuário
          </v-btn>
          <ProfessionalRecordSearch
            @input="handleRecordSelected($event)"
            ref="ProfessionalRecordSearch"
          />
        </v-col>
      </v-row>
    </template>
    <template v-if="customerProfessionalRecord.professional_record">
      <div class="d-flex justify-end">
        <v-btn @click="handleSave()" class="mr-2" outlined color="primary">
          Salvar
        </v-btn>
        <v-btn @click="handlePrint()" icon color="primary">
          <app-icon>print</app-icon>
        </v-btn>
        <app-delete-btn
          v-if="hasProfessionalRecord"
          @click="handleDelete()"
          type="icon"
        />
      </div>
      <v-row class="mt-10">
        <v-col class="pa-0 mx-4">
          <draggable
            v-model="customerProfessionalRecord.professional_record"
            @start="drag = true"
            @end="drag = false"
            handle=".handle"
          >
            <template
              v-for="(
                question, index
              ) in customerProfessionalRecord.professional_record"
            >
              <div :key="index">
                <template v-if="question.type == 'question'">
                  <v-card outlined class="mb-6">
                    <v-card-text class="col">
                      <div>
                        <span
                          class="d-flex align-center justify-space-between"
                          style="font-size: 18px"
                        >
                          {{ question.question }}

                          <div class="d-flex justify-space-between">
                            <v-btn
                              icon
                              @click="openRecordQuestionForm(question, index)"
                              text
                            >
                              <app-icon>edit</app-icon>
                            </v-btn>
                            <app-delete-btn
                              @click="removeQuestion(index)"
                              type="icon"
                            />
                            <v-btn icon text>
                              <app-icon class="handle">
                                drag_indicator
                              </app-icon>
                            </v-btn>
                          </div>
                        </span>

                        <v-textarea
                          v-model="question.answer"
                          solo
                          flat
                          background-color="grey lighten-4"
                          auto-grow
                          placeholder="Resposta..."
                          full-width
                        />
                      </div>
                    </v-card-text>
                  </v-card>
                </template>
                <template v-if="question.type == 'heading'">
                  <div
                    class="d-flex justify-space-between pr-4"
                    style="widht: 100%"
                  >
                    <h3 class="mt-6 mb-2">
                      {{ question.heading }}
                    </h3>
                  </div>
                </template>
              </div>
            </template>
          </draggable>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-0 mx-4">
          <v-card @click="openRecordQuestionForm()" outlined>
            <v-card-text
              style="font-size: 125%"
              class="d-flex justify-center"
              primary-title
            >
              Adicionar Pergunta
              <v-icon class="ml-2">mdi-plus</v-icon>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-end mt-12 mx-2">
        <app-delete-btn
          v-if="customerProfessionalRecord.id"
          class="mr-auto"
          @click="handleDelete()"
        />
        <v-btn @click="handleSave()" color="primary"> Salvar </v-btn>
      </v-row>
    </template>
    <ProfessionalRecordQuestionForm
      ref="ProfessionalRecordQuestionForm"
      @update="updateQuestion($event)"
      @store="addQuestion($event)"
    />
  </div>
</template>

<script>
import ProfessionalRecordSearch from "@/components/professional-record/ui/ProfessionalRecordSearch.vue";
import ProfessionalRecordQuestionForm from "@/components/professional-record/forms/ProfessionalRecordQuestionForm";
import draggable from "vuedraggable";

export default {
  components: {
    ProfessionalRecordSearch,
    ProfessionalRecordQuestionForm,
    draggable,
  },
  props: {
    value: {},
    customer: {},
  },
  data() {
    return {
      customerProfessionalRecord: {},
      baseCustomerRecord: {
        customer_id: null,
        name: null,
        professional_record: null,
      },
    };
  },

  watch: {
    customer(val) {
      this.setRecordData();
    },
  },

  created() {
    this.reset();
  },

  computed: {
    hasProfessionalRecord() {
      return this.customerProfessionalRecord.id != null;
    },
  },

  methods: {
    reset() {
      this.customerProfessionalRecord = JSON.parse(
        JSON.stringify(this.baseCustomerRecord)
      );
    },
    updateQuestion(data) {
      this.$set(
        this.customerProfessionalRecord.professional_record,
        this.editIndex,
        data
      );
    },
    async addQuestion(data) {
      await this.customerProfessionalRecord.professional_record.push({
        type: "question",
        question: data.question,
        question_type: data.question_type,
        answer: data.answer,
        options: data.options,
      });
    },

    removeQuestion(index) {
      this.$delete(this.customerProfessionalRecord.professional_record, index);
    },
    openRecordQuestionForm(question, index) {
      this.editIndex = index;
      this.$refs.ProfessionalRecordQuestionForm.open(question, index);
    },

    setRecordData() {
      if (this.customer.customer_professional_record) {
        this.customer.customer_professional_record.professional_record =
          JSON.parse(
            this.customer.customer_professional_record.professional_record
          );
        this.customerProfessionalRecord =
          this.customer.customer_professional_record;
      } else {
        this.customerProfessionalRecord.professional_record = null;
      }

      this.customerProfessionalRecord.customer_id = this.customer.id;
    },

    handleSelectRecord() {
      this.$refs.ProfessionalRecordSearch.open();
    },

    handleRecordSelected(professional_record) {
      professional_record.questions = JSON.parse(professional_record.questions);

      this.customerProfessionalRecord.professional_record_id =
        professional_record.id;
      this.customerProfessionalRecord.name = professional_record.name;
      this.customerProfessionalRecord.professional_record =
        professional_record.questions;
    },

    handleSave() {
      this.$http
        .store(
          "customer/customer-professional-record",
          this.customerProfessionalRecord
        )
        .then((response) => {
          this.processSaved();
        })
        .catch((error) => {});
    },
    handleDelete() {
      this.$loading.start();
      this.$http
        .destroy(
          "customer/customer-professional-record",
          this.customerProfessionalRecord.id
        )
        .then((response) => {
          this.$snackbar({
            message: "Prontuário apagado com sucesso",
            color: "success",
            timeout: 1000,
            showClose: false,
          });
          this.$emit("delete");
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    processSaved() {
      this.$emit("store");
      this.$snackbar({
        message: "Prontuário salva com sucesso",
        color: "success",
        timeout: 1000,
        showClose: false,
      });
    },
    handlePrint() {
      this.$doc.printView(`/cliente/${this.customer.id}/imprimir/prontuario`);
    },
  },
};
</script>

<style>
</style>