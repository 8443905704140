<template>
  <div>
    <portal to="mobileMenuButton">
      <v-btn icon @click.prevent="$router.push('/clientes')">
        <app-icon>navigate_before</app-icon>
      </v-btn>
    </portal>
    <v-row>
      <v-col class="pr-md-8" cols="12" md="3">
        <CustomerProfileStatsSection
          class="mb-4"
          :customer="customer"
          :stats="stats"
          :dueTransactionsSum="dueTransactionsSum"
        />
        <v-alert
          class="my-0"
          text
          type="info"
          v-if="showNoSelectedProfessionalAlert"
        >
          Nenhum profissional selecionado
          <br />
          <small
            >Selecione um profissional para ter acesso aos dados clínicos do
            {{ customerAlias.toLowerCase() }}.</small
          >
        </v-alert>
        <v-list class="px-0 mt-2" nav v-model="visibleSection">
          <v-list-item-group ac color="primary">
            <v-list-item
              @click="
                handleShowSection('/cliente/ficha-cadastral', 'Ficha Cadastral')
              "
            >
              <v-list-item-icon>
                <app-icon> mdi-account </app-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Dados principais </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <app-icon>navigate_next</app-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item
              v-if="canViewCustomerSensitiveData"
              @click="handleShowSection('/cliente/prontuario', 'Prontuário')"
            >
              <v-list-item-icon>
                <app-icon> mdi-clipboard-text-outline </app-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Prontuário </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <app-icon>navigate_next</app-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item
              v-if="canViewCustomerSensitiveData"
              @click="handleShowSection('/cliente/anamnese', 'Anamnese')"
            >
              <v-list-item-icon>
                <app-icon> mdi-forum-outline </app-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Anamnese </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <app-icon>navigate_next</app-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item
              @click="
                handleShowSection(
                  '/cliente/sessoes',
                  'Anotações da Sessão',
                  getSessions
                )
              "
            >
              <v-list-item-icon>
                <app-icon> mdi-pencil </app-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Anotações da Sessão </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <app-icon>navigate_next</app-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item
              v-if="$acl.canViewTransactions()"
              @click="
                handleShowSection('/cliente/financeiro', 'Financeiro', getBills)
              "
            >
              <v-list-item-icon>
                <app-icon> mdi-currency-usd </app-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Financeiro </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <app-icon>navigate_next</app-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item
              @click="
                handleShowSection(
                  '/cliente/notificacoes-cobranca',
                  'Notificações de Cobrança',
                  getBillingsNotifications
                )
              "
            >
              <v-list-item-icon>
                <app-icon> mdi-bell-outline </app-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Cobranças </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <app-icon>navigate_next</app-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item
              @click="
                handleShowSection(
                  '/cliente/documentos',
                  'Documentos',
                  getDocuments
                )
              "
            >
              <v-list-item-icon>
                <app-icon> mdi-file-document-outline </app-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Documentos </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <app-icon>navigate_next</app-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item
              @click="
                handleShowSection('/cliente/arquivos', 'Arquivos', getFiles)
              "
            >
              <v-list-item-icon>
                <app-icon> mdi-file </app-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Arquivos </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <app-icon>navigate_next</app-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <div class="d-flex justify-center d-none d-md-flex mt-5">
          <v-btn small color="primary" @click="handleCustomerExport()">
            <app-icon left>download </app-icon> Exportar
          </v-btn>
        </div>
      </v-col>
      <v-col
        class="pl-md-8"
        v-if="!$vuetify.breakpoint.mobile"
        cols="12"
        md="9"
      >
        <v-row>
          <v-col cols="12">
            <h2 class="mb-4">{{ sectionTitle }}</h2>
            <CustomerFormSection
              :customer="customer"
              ref="CustomerFormSection"
              v-show="visibleSection == '/cliente/ficha-cadastral'"
              @store="setCustomer($event)"
            />
            <CustomerProfessionalRecordForm
              @input="update()"
              ref="CustomerProfessionalRecordForm"
              v-show="visibleSection == '/cliente/prontuario'"
              @delete="select()"
              :customer="customer"
            />
            <CustomerAnamneseForm
              @input="update()"
              ref="CustomerAnamneseForm"
              v-show="visibleSection == '/cliente/anamnese'"
              @delete="select()"
              :customer="customer"
            />
            <CustomerSessions
              ref="CustomerSessions"
              v-show="visibleSection == '/cliente/sessoes'"
              :customer="customer"
            />
            <CustomerBills
              ref="CustomerBills"
              v-show="visibleSection == '/cliente/financeiro'"
              :customer="customer"
              @store="select($event)"
            />
            <CustomerDocumentsSection
              ref="CustomerDocumentsSection"
              :customer="customer"
              v-show="visibleSection == '/cliente/documentos'"
            />
            <CustomerFileSection
              ref="CustomerFileSection"
              :customer="customer"
              v-show="visibleSection == '/cliente/arquivos'"
            />
            <CustomerBillingsNotificationsSection
              ref="CustomerBillingsNotificationsSection"
              v-show="visibleSection == '/cliente/notificacoes-cobranca'"
              :customer="customer"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CheckUnsavedChangesMixin from "../mixins/App/CheckUnsavedChangesMixin";
import CustomerFormSection from "../components/customers/forms/CustomerFormSection.vue";
import CustomerProfileStatsSection from "../components/customers/sections/CustomerProfileStatsSectionV2.vue";
import CustomerAnamneseForm from "@/components/customers/forms/CustomerAnamneseForm.vue";
import CustomerSessions from "@/components/customers/sections/CustomerSessions.vue";
import CustomerBills from "@/components/customers/sections/CustomerBills.vue";
import CustomerDocumentsSection from "@/components/customers/sections/CustomerDocumentsSection.vue";
import CustomerProfessionalRecordForm from "@/components/customers/forms/CustomerProfessionalRecordForm.vue";
import CustomerFileSection from "@/components/customers/sections/CustomerFileSection.vue";
import CustomerBillingsNotificationsSection from "@/components/customers/sections/CustomerBillingsNotificationsSection.vue";
import { customerAlias } from "@/utils/customerAlias";

export default {
  mixins: [CheckUnsavedChangesMixin],

  components: {
    CustomerFormSection,
    CustomerProfileStatsSection,
    CustomerAnamneseForm,
    CustomerSessions,
    CustomerBills,
    CustomerDocumentsSection,
    CustomerProfessionalRecordForm,
    CustomerFileSection,
    CustomerBillingsNotificationsSection,
  },

  data: () => ({
    customerAlias: customerAlias(),

    visibleSection: "/cliente/ficha-cadastral",

    sectionTitle: "Ficha Cadastral",

    customer: {},

    customerProfessionalId: null,

    dueTransactionsSum: 0,

    stats: {},

    active: null,
  }),

  created() {
    this.select();

    this.getStats();
  },

  computed: {
    isClinic() {
      return this.$acl.isClinic();
    },

    showCustomerBills() {
      return (
        this.$acl.isProfessional() || this.$acl.can("can_access_financial")
      );
    },

    canViewCustomerSensitiveData() {
      return this.$acl.canViewCustomerSensitiveData(
        this.customerProfessionalId
      );
    },

    showNoSelectedProfessionalAlert() {
      if (this.customer.id) {
        return this.isClinic && this.customer.professional == null;
      }
    },

    isEditing() {
      return this.$store.state.app.isEditing;
    },
  },

  methods: {
    handleShowSection(route, sectionTitle, callback = null) {
      if (this.$vuetify.breakpoint.mobile) {
        this.$router.push(route + "/" + this.$route.params.id);
      } else {
        this.sectionTitle = sectionTitle;
        this.visibleSection = route;
        if (callback) {
          callback();
        }
      }
    },

    async select(page = 1) {
      this.$loading.start();
      await this.$http
        .show("customer/customer", this.$route.params.id)
        .then((response) => {
          this.customer = response.customer;
          this.customerProfessionalId = response.customer.professional_id;
          this.dueTransactionsSum = response.due_transactions_sum;
          // this.getBills();
          // this.getSessions();
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    getStats() {
      let params = {
        customer_id: this.$route.params.id,
      };

      this.$http
        .index("sessions/session-stats", params)
        .then((response) => {
          this.stats = response.stats;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    setCustomer(customer) {
      this.customer = customer;
      this.customerProfessionalId = customer.professional_id;
    },

    getSessions() {
      this.$refs.CustomerSessions.select();
    },
    getBills() {
      this.$refs.CustomerBills.select();
    },
    getDocuments() {
      this.$refs.CustomerDocumentsSection.select();
    },

    getFiles() {
      this.$refs.CustomerFileSection.select();
    },
    getBillingsNotifications() {
      this.$refs.CustomerBillingsNotificationsSection.select();
    },

    handleCustomerExport() {
      this.$loading.start();
      this.$http
        .download("customer/customer-export", {
          customer_id: this.customer.id,
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Ficha do ${customerAlias()} :${this.customer.name}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          this.$loading.finish();
        })
        .catch((error) => {
          console.log(error);
          this.$loading.finish();
        });
    },
  },
};
</script>

<style></style>
