<template>
  <div>
    <portal to="mobileMenuButton">
      <v-btn icon @click.prevent="$router.push('/cliente/' + $route.params.id)">
        <app-icon>navigate_before</app-icon>
      </v-btn>
    </portal>
    <CustomerAnamneseForm
      v-show="booted"
      @input="update()"
      ref="CustomerAnamneseForm"
      @delete="select()"
      :customer="customer"
    />
  </div>
</template>

<script>
import CustomerAnamneseForm from "../../components/customers/forms/CustomerAnamneseForm.vue";
export default {
  components: { CustomerAnamneseForm },
  data: () => ({
    customer: {},
    booted : false,
  }),

  created() {
    this.select();
  },

  methods: {
    async select() {
      this.$loading.start();

      await this.$http
        .show("customer/customer", this.$route.params.id)
        .then((response) => {
          this.customer = response.customer;
          this.booted = true;
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    update() {
      this.$http
        .update("customer/customer", this.customer.id, this.customer)
        .then((response) => {
          console.log(response);
          this.processSaved();
        });
    },
  },
};
</script>

<style></style>
