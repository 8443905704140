<template>
  <div>
    <h1 class="text-center mb-4">Recuperar senha</h1>
    <template v-if="!success">
      <v-row class="justify-center">
        <v-col cols="10" md="12">
          <v-text-field
            color="primary"
            v-model="form.email"
            label="Seu email"
            placeholder="exemplo@exemplo.com"
          />
        </v-col>
      </v-row>
      <v-row class="mt-10 justify-center">
        <v-col cols="10" md="12">
          <v-btn @click="resetPassword()" block color="primary">
            Recuperar senha
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template v-if="success">
      Uma notificação foi enviada para seu WhatsApp com o link para criar uma
      nova senha.
    </template>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        email: null,
      },

      success: false,
    };
  },

  methods: {
    resetPassword() {
      this.$http.$post("auth/recover-password", this.form).then((response) => {
        this.success = true;
      });
    },
  },
};
</script>

 