<template>
  <div>
    <h1 class="text-center mb-4">Redefinir senha</h1>
    <template v-if="!success">
      <v-row class="mt-3">
        <v-col md="6">
          <app-password-field
            color="primary"
            @keyup.enter.native="login"
            v-model="form.password"
            placeholder="Senha"
            :name="'password'"
          />
        </v-col>
        <v-col md="6">
          <app-password-field
            color="primary"
            @keyup.enter.native="login"
            v-model="form.password_confirmation"
            placeholder="Confirmar Senha"
            :name="'password'"
          />
        </v-col>
      </v-row>

      <v-row class="mt-10">
        <v-col>
          <v-btn @click="resetPassword()" block color="primary">
            Redefinir Senha
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template v-if="success">
      <v-row>
        <v-col class="text-center">
          Sua senha foi atualizada!
          <br />
          <v-btn class="mt-5" color="primary" @click="$router.push('/login')"
            >Ir para login</v-btn
          >
        </v-col></v-row
      >
    </template>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        token: null,
        password: null,
        password_confirmation: null,
      },
      success: false,
    };
  },

  created() {
    this.form.token = this.$route.params.token;
  },

  methods: {
    resetPassword() {
      this.$http.$post("auth/reset-password", this.form).then((response) => {
        this.success = true;
      });
    },
  },
};
</script>

 