<template>
  <div>
    <portal to="mobileMenuButton">
      <v-btn icon @click.prevent="$router.push('/cliente/' + $route.params.id)">
        <app-icon>navigate_before</app-icon>
      </v-btn>
    </portal>
    <CustomerSessions
      ref="CustomerSessions"
      :customer="customer"
    />
  </div>
</template>

<script>

import CustomerSessions from "@/components/customers/sections/CustomerSessions.vue";

export default {
  
  components: { CustomerSessions },
  
  data: () => ({
    booted : false,
    customer: {},
  }),

  created() {
    this.select();
  },

  methods: {
    async select() {
      this.$loading.start();

      await this.$http
        .show("customer/customer", this.$route.params.id)
        .then((response) => {
          
          this.customer = response.customer;
      
          this.booted = true;

          this.$refs.CustomerSessions.select();
    

          this.$loading.finish();

        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
  },
};
</script>

<style></style>
