<template>
  <div>
    <v-row>
      <v-col>
        <v-btn
          class="d-flex ml-auto"
          color="primary"
          @click="handleStoreDocument()"
        >
          Gerar Novo Documento
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <app-pagination @click="select($event)" :data="documents">
          <v-data-table
            disable-sort
            hide-default-footer
            :loading="loading"
            :headers="headers"
            :items="documents.data"
            :items-per-page="-1"
            :mobile-breakpoint="0"
            @click:row="handleStoreDocument($event.id)"
          >
            <template v-slot:[`item.action`]="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text icon color="primary" v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item @click.stop="printDocument(item.id)">
                    <v-list-item-icon>
                      <v-icon color="primary"> mdi-printer</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> Imprimir </v-list-item-title>
                  </v-list-item>

                  <app-delete-btn
                    @click="deleteDocument(item.id)"
                    type="list-item"
                  />
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </app-pagination>
      </v-col>
    </v-row>
    <StoreDocumentForm
      ref="StoreDocumentForm"
      :customer="customer"
      :documentData="customer"
      @store="select()"
    />
  </div>
</template>

<script>
import StoreDocumentForm from "@/components/customers/sections/StoreDocumentForm";

export default {
  components: { StoreDocumentForm },

  props: {
    customer: {},
  },

  data() {
    return {
      searchParams: {},
      documents: {},
      headers: [
        { text: "Descrição", align: "start", value: "document_name" },

        { text: "", value: "action", align: "center", width: "1%" },
      ],
      loading: false,
    };
  },

  mounted() {
    this.select();
  },
  created() {
    this.searchParams.customer_id = this.$route.params.id;
  },

  methods: {
    handleStoreDocument(id) {
      this.$refs.StoreDocumentForm.open(id);
    },
    printDocument(document_id) {
      this.$doc.printView(`/documento/imprimir/${document_id}`);
    },

    deleteDocument(document_id) {
      this.$http
        .destroy("customer/customer-documents", document_id)
        .then((response) => {
          this.select();
          this.loading = false;
        })
        .catch((error) => {
          this.select();
          this.loading = false;
        });
    },

    select(page = 1) {
      this.searchParams.page = page;
      this.loading = true;
      this.$http
        .index("customer/customer-documents", this.searchParams)
        .then((response) => {
          this.documents = response.documents;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>