<template>
  <div>
    <app-pagination @click="select($event)" :data="sessions">
      <v-data-table
        disable-sort
        hide-default-footer
        :loading="loading"
        :headers="headers"
        :items="sessions.data"
        :items-per-page="-1"
        @click:row="openSessionDialog($event)"
      >
        <template v-slot:[`item.start_at`]="{ item }">
          <v-row class="flex-column my-1">
            {{ $format.dateTimeBr(item.start_at) }}
            <br />
            <small v-if="item.annotations" class="text--secondary my-5">
              {{ item.annotations }}</small
            >
            <small v-else class="text--secondary my-1">
              Nenhuma anotação informada</small
            >
          </v-row>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          {{ $format.decimal(item.value) }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <session-status-chip :value="item.status" />
          <!-- <v-chip small :color="statuses[item.status].color">{{
            statuses[item.status].text
          }}</v-chip> -->
        </template>
      </v-data-table>
    </app-pagination>
    <SessionDialog @store="select()" ref="SessionDialog" />
    <TransactionForm @store="select()" ref="TransactionForm" />
  </div>
</template>

<script>
import SessionDialog from "@/components/session/sections/SessionDialog.vue";
import TransactionForm from "@/components/transactions/form/TransactionForm.vue";
import SessionStatusChip from "@/components/schedule/sections/SessionStatusChip.vue";

export default {
  components: {
    SessionDialog,
    TransactionForm,
    SessionStatusChip,
  },

  props: {
    customer: {},
  },
  data() {
    return {
      loading: false,
      sessions: {},
      searchParams: {
        page: null,
        customer_id: null,
      },

      statuses: {
        pending: { text: "Agendado", color: "primary" },
        canceled: { text: "Cancelado", color: "error" },
        missed: { text: "Faltou", color: "error" },
        shown: { text: "Compareceu", color: "success" },
      },

      headers: [
        { text: "Data e Hora", align: "start", value: "start_at" },
        { text: "Status", align: "center", value: "status", width: "10%" },
      ],
    };
  },

  watch: {
    // customer() {
    //   console.log('set')
    //   this.searchParams.customer_id = this.customer.id;
    // },
  },

  created() {
    this.setHeaders();
    this.searchParams.customer_id = this.$route.params.id;
  },

  methods: {
    async select(page = 1) {
      this.loading = true;
      this.searchParams.page = page;
      await this.$http
        .index("sessions/session", this.searchParams)
        .then((response) => {
          this.sessions = response.sessions;
        });
      this.loading = false;
    },
    openSessionDialog(data) {
      // if (data.transaction.status == "pending") {
      //   this.$refs.TransactionForm.open(data.transaction.id);
      // } else {
      this.$refs.SessionDialog.open(data.id);
      // }
    },
    setHeaders() {
      if (this.$acl.isClinic()) {
        this.headers = [
          { text: "Data e Hora", align: "start", value: "start_at" },
          { text: "Profissional", value: "professional.name", align: "center" },
          { text: "Sala", value: "office.name", align: "center" },
          { text: "Status", align: "center", value: "status", width: "10%" },
        ];
      }
    },
  },
};
</script>

<style></style>
