<template>
  <v-container class="pa-0" fluid style="height: 100%">
    <div class="d-flex flex-md-row flex-column" style="height: 100%">
      <div
        class="column order-1 order-md-0 left-column d-flex align-md-center align-start pa-4"
        style="background-color: #624de3"
      >
        <v-img
          class="left-column-image"
          contain
          src="../../assets/images/auth/woman_hug.png"
        />
      </div>

      <div
        class="column order-0 order-md-1 d-flex flex-column justify-center pl-md-12 px-4 py-8"
        style="background-color: #fff"
      >
        <div id="logo" class="mb-md-15 mb-6">
          <v-img width="144" src="../../assets/images/auth/logo_roxo.png" />
        </div>

        <div id="heading" class="mb-md-15 mb-6">
          <h1 class="mb-2">Teste por 7 dias Grátis!</h1>
          <p>Não precisa de cartão de crédito para testar.</p>
        </div>

        <div id="invitation" class="form mb-2" v-if="form.recommender_name">
          <v-alert text class="rounded-xl" color="primary">
            <span style="font-size: 24px;">{{ form.recommender_name }} já usa o psicoplanner.</span>
            <div class="mt-4">
              <span>Junte-se a milhares de psi's que usam o psicoplanner e adoram!</span>
            </div>
          </v-alert>
        </div>


        <div id="login-form" class="form">
          <div class="mb-4" style="width: 100%">
            <app-text-field
              :dense="false"
              solo
              flat
              background-color="#F4F4FB"
              placeholder="Digite seu NOME"
              hide-details="auto"
              @keyup.enter.native="login"
              v-model="form.user_name"
            />
          </div>
          <div class="mb-4" style="width: 100%">
            <app-text-field
              :dense="false"
              solo
              flat
              background-color="#F4F4FB"
              placeholder="Digite seu E-MAIL"
              hide-details="auto"
              type="email"
              v-model="form.email"
            />
          </div>
          <div class="mb-4" style="width: 100%">
            <app-whatsapp-field
              id="whatsapp"
              :dense="false"
              solo
              flat
              background-color="#F4F4FB"
              placeholder="Digite seu WHATSAPP"
              hide-details="auto"
              @keyup.enter.native="login"
              v-model="form.phone_wa"
            />
          </div>
          <div class="mb-4" style="width: 100%">
            <app-password-field
              solo
              :dense="false"
              flat
              background-color="#F4F4FB"
              placeholder="Sua SENHA"
              hide-details="auto"
              @keyup.enter.native="login"
              v-model="form.password"
              type="password"
            />
          </div>
          <div class="mb-4">
            <v-btn class="primary" @click="signup()" dark rounded>
              CRIAR MINHA CONTA
            </v-btn>
          </div>
          <div class="d-md-flex justify-space-between">
            <div style="cursor: pointer" @click="redirectToLogin()">
              <small class="text--secondary">Já possui conta?</small>
              <small class="primary--text"> Faça login aqui!</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      form: {
        user_name: null,
        company_name: null,
        email: null,
        password: null,
        password_confirmation: null,
        recommender_id: null,
        recommender_name: null,
        signup_metadata: null,
      },
    };
  },

  mounted() {
    this.setSignupMetadata();
  },

  methods: {
    setSignupMetadata() {
      try {
        this.form.signup_metadata = window.location.href;

        if (this.$route.query.company) {
          this.form.recommender_id = this.$route.query.company;
          this.form.recommender_name = this.$route.query.company_name;
        }

      } catch (error) {
        console.log("error", error);
      }
    },

    signup() {
      this.$loading.start();
      this.$http
        .store("app/signup", this.form)
        .then(async (response) => {

          this.$auth.storeToken(response.token);

          await this.$auth.getUserData();

          this.track({
            email: this.form.email,
            company_name: this.form.company_name,
            phone: this.form.phone_wa,
          });

          this.$router.push("/bem-vindo");

          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    track(params) {
      this.$tracker.signUp(params);
    },

    redirectToLogin() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style scoped>
.left-column {
  background-color: #624de3;
  background-image: url("../../assets/images/auth/bg.png");
  background-size: cover;
}

/* desktop */
@media (min-width: 960px) {
  .column {
    width: 50%;
    height: 100%;
  }

  .form {
    padding-right: 156px;
  }

  .left-column-image {
    height: 600px;
  }
}

/* mobile*/
@media (max-width: 960px) {
  .column {
    width: 100%;
  }

  .left-column-image {
    height: 400px;
  }
}
</style>

 