<template>
  <v-dialog persistent scrollable v-model="dialog" width="600px" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-card-title class="text">
        {{ title }} cobrança mensal
        <app-delete-btn v-if="!isInsert" @click="handleDelete()" x-small class="ml-auto" type="icon" />
      </v-card-title>
      <v-card-text>

        <br>

        <v-row>
          <v-col md="6" cols="12">
            <app-date-picker outlined label="Data de início" v-model="form.start_date" />
          </v-col>
          <v-col md="6" cols="12">
            <app-date-picker outlined label="Data de término" v-model="form.end_date" />
          </v-col>
        </v-row>

        <v-row>
          <v-col md="6" cols="12">
            <app-number-field outlined label="Valor" v-model="form.value" />
          </v-col>
          <v-col md="6" cols="12">
            <app-number-field outlined label="Dia do mês" v-model="form.day_of_month" :max="30" :precision="0" />
          </v-col>
        </v-row>
        <br>
        <v-alert color="primary" text>
          <strong>Atenção:</strong> A recorrência gerar um lançamento mensal 5 dias antes do dia do mês informado.
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <app-btn @click="dialog = false" rounded outlined>Voltar</app-btn>
        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />

        <app-btn @click="handleSave()" class="mr-2" rounded color="primary">Salvar</app-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>import SelectProfessional from "@/components/app/forms/SelectProfessional";

export default {
  components: { SelectProfessional },

  data() {
    return {

      dialog: false,

      baseForm: {
        customer_id: null,

        value: null,
        start_date: null,
        end_date: null,
        day_of_month: null,
      },

      form: {}
    };
  },

  created() {
    this.reset();
  },

  computed: {
    company() {
      return this.$store.state.auth.company;
    },

    isClinic() {
      return this.$store.state.auth.company.type == "clinic";
    },

    user() {
      return this.$store.state.auth.user;
    },

    isProfessional() {
      return this.user.company_user.is_professional;
    },

    showSelectProfessional() {
      return this.isClinic && this.$acl.can("can_manage_all_calendar");
    },
    isInsert() {
      return !this.form.id;
    },
    title() {
      return this.isInsert ? 'Cadastrar' : 'Editar';
    }
  },

  methods: {
    open(customer, id = null) {
      this.reset();

      this.form.customer_id = customer.id;
      this.form.professional_id = customer.professional_id;

      if (id) {
        this.$loading.start();
        this.$http.show('transactions/monthly-billing', id).then((response) => {
          this.form = response.monthly_billing;
          this.$loading.finish()
        })
          .catch((error) => this.$loading.finish());
      }

      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },


    handleSave() {
      if (this.form.id) {
        this.update();
      } else {
        this.store();
      }
    },

    async handleDelete() {
      this.$loading.start();
      await this.$http.destroy('transactions/monthly-billing', this.form.id)
        .then((response) => {
          this.$emit("store");
          this.dialog = false;
          this.$loading.finish();
        })
        .catch((error) => this.$loading.finish());
    },

    async store() {
      this.$loading.start();
      await this.$http.store('transactions/monthly-billing', this.form)
        .then((response) => this.processSave())
        .catch((error) => this.$loading.finish());
    },

    async update() {
      this.$loading.start();
      await this.$http.update('transactions/monthly-billing', this.form.id, this.form)
        .then((response) => this.processSave())
        .catch((error) => this.$loading.finish());
    },

    processSave() {
      this.$loading.finish()
      this.$emit("store");
      this.dialog = false;
    },
  },
};
</script>

<style></style>